import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import STcontext from "../../../context/STcontext";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AffectationSim from "./AffectationSim";
import pusher from "../../../../pusher";
import ConfirmPopup from "../../../updateAndDeleteButtons/ConfirmPopup";
import Updateapp from "../../../Comptable/Appareil/SGA/Updateapp";
import ComptableContext from "../../../context/ComptableContext";
import AddDevices from "../modelAppareil/AddDevices";

const Gps = () => {
  const appareilId = 1
  const Navigate = useNavigate();

  const {
    handleAffectedTechnicianOption,
    SubmittedToTechnician,
    technicien,
    technicianAll,
    handleAffectedArticleOption,
    appareil,
    getAppareil,
    errorsMessages,
    handleConfirmAction,
    setGlobalSearch,
    globalSearch,
    globalSearchRes,
    handleGlobalSearch,
  } = useContext(STcontext);
  const { updateDeviceSubmit } = useContext(ComptableContext);
  useEffect(() => {
    setGlobalSearch({...globalSearch,search:""})
    technicianAll();
    getAppareil();
    
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getAppareil();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);
  const [selectedAppareils, setSelectedAppareils] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectAppareilBy, setSelectAppareilBy] = useState(0);
  const [GPS, setGPS] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    serial_number: { value: null },
    nameModele: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    nameType: { value: null, matchMode: FilterMatchMode.IN },
    numberBox: { value: null, matchMode: FilterMatchMode.IN },
  });

  {
    /* function filter GPS by type [new GPS, GPS client,GPS occasion, GPS] */
  }
  const filterAppareilsBy = (appareil) => {
    if (selectAppareilBy == 0) {
      setGPS(
        appareil.filter(
          (filterGPS) =>
            filterGPS.etat === 0 &&
            filterGPS.garantie === 0 &&
            filterGPS.proprietaire === 0
        )
      );
    } else if (selectAppareilBy == 1) {
      setGPS(
        appareil.filter(
          (filterGPS) => filterGPS.etat === 0 && filterGPS.proprietaire === 1
        )
      );
    } else if (selectAppareilBy == 2) {
      setGPS(
        appareil.filter(
          (filterGPS) =>
            filterGPS.etat === 0 &&
            filterGPS.proprietaire === 0 &&
            filterGPS.garantie === 1
        )
      );
    } else if (selectAppareilBy == 3) {
      setGPS(appareil.filter((filterGPS) => filterGPS.etat === 3));
    }
  };

  useEffect(() => {
    filterAppareilsBy(appareil.AllGPS);
  }, [appareil]);
  useEffect(() => {
    filterAppareilsBy(appareil.AllGPS);
  }, [selectAppareilBy]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    if (selectedAppareils != null) {
      const appareilsId = selectedAppareils.map((appareil) => appareil.id);
      handleAffectedArticleOption(appareilsId);
    }
  }, [selectedAppareils]);

  useEffect(() => {
    setSelectedAppareils(null);
  }, [appareil.AllGPS]);

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder=" IMEI, MODEL, BOX"
          />
        </span>
      </div>
    );
  };


  const renderHeaderGlobalSearch = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalSearch.search}
            onChange={(e)=>handleGlobalSearch(e,appareilId)}
            placeholder="recherche globale"
          />
        </span>
      </div>
    );
  };

  // table appareil pre user

  const [pages, setPagesAppareilPreUser] = useState(1);
  const [rowsPrePage, setRowsPrePage] = useState(5);
  const lastAppareilIndex = pages * rowsPrePage;
  const firstAppareilIndex = lastAppareilIndex - rowsPrePage;
  const currentAppareilPreUser = appareil.gpsUserAssociate.slice(
    firstAppareilIndex,
    lastAppareilIndex
  );
  const [devicesPreUser,setDevicesPreUser]=useState(currentAppareilPreUser)
  
  const filterAppareilPreUser =()=>{
    if (globalSearchRes !="" && globalSearchRes !=null) {
      const getUserId =  globalSearchRes.map((appareilPreUser)=>appareilPreUser.userId);
      setDevicesPreUser(currentAppareilPreUser.filter(devicePreUser=>getUserId.includes(devicePreUser.id)))
    }
    else{
      setDevicesPreUser(currentAppareilPreUser)
    }
  }

  let numberPagesAppareilPreUser = [];
  for (
    let p = 1;
    p <= Math.ceil(appareil.gpsUserAssociate.length / rowsPrePage);
    p++
  ) {
    numberPagesAppareilPreUser.push(p);
  }

  
  useEffect(()=>{
    filterAppareilPreUser();
  },[globalSearchRes])

  const header = renderHeader();

  const renderSimRelating = (rowDate) => {
    return (
      <>
        <AffectationSim
          data={rowDate}
          SimData={appareil.articleWithoutCombination}
        />
      </>
    );
  };
  const renderUpdateAndDeleteAppareil = (rowDate) => {
    return (
      <div style={{ display: "flex" }}>
        <Updateapp
          data={rowDate}
          updateFunction={(e) => updateDeviceSubmit(e)}
        />
        <ConfirmPopup
          message="Etes vous sûre de vouloir supprimer cette article?"
          id={rowDate.id}
          deleteFunction={(e) => handleConfirmAction(rowDate.id)}
        />
      </div>
    );
  };
  const dateBodyFormat = (rowDate) => {
    return formatDate(rowDate.created_at);
  };
  const formatDate = (value) => {
    return new Date(value).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "2rem",
        marginTop: "1rem",
        borderRadius: "2rem",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "2rem",
        }}
      >
        {/* number device counter */}
        <div style={{ padding: "0rem 2rem 1rem 2rem " }}>
          <div>
            <h5>
              GPS &nbsp; <span class="vl"></span> &nbsp;{" "}
              <span style={{ fontSize: "15px" }}> {GPS?.length} Appareils</span>{" "}
            </h5>
          </div>
        </div>

        {/* filter GPS by type [new GPS, GPS client,GPS occasion, GPS] */}
        <Form>
          <Row md="2">
            <Form.Group
              className="mb-2 mx-2"
              as={Col}
              md="4"
              controlId="validationCustom01"
            >
              <Form.Label>select les appareils par: </Form.Label>
              <Form.Select
                required
                type="text"
                name="type"
                onChange={(e) => setSelectAppareilBy(e.target.value)}
              >
                <option disabled>select les appareils par</option>
                <option value="0" selected>
                  Nouveaux Appareils
                </option>
                <option value="1">Appareils Client</option>
                <option value="2">Appareils Bon Occasion</option>
                <option value="3">Appareils En dommage</option>
              </Form.Select>
            </Form.Group>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                width: "64%",
              }}
            >
              <AddDevices />
            </div>
          </Row>
        </Form>
        <DataTable
          value={GPS}
          selectionMode={rowClick ? null : "checkbox"}
          selection={selectedAppareils}
          onSelectionChange={(e) => setSelectedAppareils(e.value)}
          dataKey="id"
          header={header}
          paginator
          sortMode="multiple"
          filters={filters}
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          globalFilterFields={[
            "serial_number",
            "nameModele",
            "nameType",
            "numberBox",
          ]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            header="#"
            headerStyle={{ width: "3rem" }}
            body={(data, options) => options.rowIndex + 1}
            bodyStyle={{ fontWeight: "bold" }}
          ></Column>
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>
          <Column field="serial_number" header="IMEI"></Column>
          <Column
            field="articleCombination_serial_number"
            header="SIM"
          ></Column>
          <Column field="nameModele" header="Model"></Column>
          <Column header="Date" dataType="date" body={dateBodyFormat}></Column>
          <Column field="numberBox" header="N.Box"></Column>
          <Column field="observation" header="Observation"></Column>
          <Column header="Affected SIM" body={renderSimRelating}></Column>
          {selectAppareilBy == 2 ? (
            <Column
              header="Action"
              body={renderUpdateAndDeleteAppareil}
            ></Column>
          ) : (
            ""
          )}
        </DataTable>
        <span style={{ color: "red" }}>
          {errorsMessages && errorsMessages.Articles
            ? errorsMessages.Articles[0]
            : ""}
        </span>
        <div
          style={{
            display: "flex",
            width: "40%",
            gap: "20px",
            padding: "2rem",
            alignItems: "start",
          }}
        >
          <div>
            <Form.Select
              aria-label="Default select example"
              name="technicien"
              onChange={handleAffectedTechnicianOption}
            >
              <option value="none" selected disabled hidden>
                Veuillez choisir un technicien
              </option>
              <option disabled>Veuillez choisir un technicien</option>
              {technicien.map((tech) => (
                <option key={tech.id} value={tech.id}>
                  {tech.name}
                </option>
              ))}
            </Form.Select>
            <span style={{ color: "red" }}>
              {errorsMessages && errorsMessages.technicien
                ? errorsMessages.technicien[0]
                : ""}
            </span>
          </div>

          <Button
            type="submit"
            variant="primary"
            onClick={(e) => SubmittedToTechnician(e, selectedAppareils)}
          >
            Envoyer
          </Button>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            backgroundColor: "white",
            width: "80%",
            padding: "2rem",
            marginTop: "1rem",
            borderRadius: "2rem",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "80%",
              padding: "1rem",
            }}
          >
            {renderHeaderGlobalSearch()}
          </div>
          <MDBTable align="middle">
            <MDBTableHead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Utilisateur</th>
                <th scope="col">Quantité</th>
                <th scope="col">Appareils</th>
              </tr>
            </MDBTableHead>
            {devicesPreUser.map((appareilPreUser, index) => (
              <MDBTableBody>
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center"></div>
                  </td>
                  <td>
                    <p className="fw-normal mb-1">{appareilPreUser.name}</p>
                  </td>

                  <td>
                    <p className="fw-normal mb-1">
                      {appareilPreUser.numberArticle}
                    </p>
                  </td>
                  <td>
                    <Button
                      className="showAppareilPreUser"
                      variant="primary"
                      onClick={() =>
                        Navigate(
                          `/Support-technique/appareils-pour-l'utilisateur/${appareilPreUser.id}/GPS/${globalSearch.search}`
                        )
                      }
                    >
                      voir les appareils
                    </Button>{" "}
                  </td>
                </tr>
              </MDBTableBody>
            ))}
          </MDBTable>
          {appareil.AllGPS.length > 0 ? (
            <nav aria-label="...">
              <MDBPagination className="mb-0 d-flex justify-content-end">
                <MDBPaginationItem disabled>
                  <MDBPaginationLink
                    href="#"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    Pages
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {numberPagesAppareilPreUser.map((numberPage, index) => (
                  <MDBPaginationItem
                    key={index}
                    className={`${numberPage == pages ? "active" : "null"}`}
                  >
                    <MDBPaginationLink
                      style={{ cursor: "pointer" }}
                      onClick={() => setPagesAppareilPreUser(numberPage)}
                    >
                      {numberPage}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination>
            </nav>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Gps;
