import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useContext } from "react";
import STcontext from "../context/STcontext";
export default function AddNewTaskST({}) {
  const {
    getTaskInfo,
    NewTask,
    handleAddTaskToTechnicien,
    AddNewTask,
    show,
    setAddNewTask,
    addTaskToTechnicien,
    setShow,
    errorsMessages,
  } = useContext(STcontext);

  const [filterClientInfo, setFilterClientInfo] = useState({});
  const [filteredUser, setFilterUser] = useState([]);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setFilterClientInfo("");
    setAddNewTask({
      Quantite: 1,
    });
  };

  useEffect(()=>{
    getTaskInfo();
  },[])

  useEffect(() => {
    setFilterUser(NewTask.Users.filter((user) => user.profil_id == 2));
     setAddNewTask({ ...AddNewTask, taskFor: 2,clientCity:1 });
  }, [NewTask.Users]);
  //filter Client Info

  const filterClientInfoByNameClient = (e, Clients) => {
    const value = e.target.value;
    const name = e.target.name;

    const getphoneNumber = Clients.filter(
      (phoneNumber) => phoneNumber.nomComplet === value
    );
    if (getphoneNumber.length > 0) {
      setFilterClientInfo(...getphoneNumber);
      setClientInfo(...getphoneNumber);
    } else {
      setClientInfo({
        [name]: value,
      });
      setFilterClientInfo({});
    }
  };
  const setClientInfo = (clientInfo) => {
    setAddNewTask({
      ...AddNewTask,
      nomComplet: clientInfo.nomComplet,
      telephone_client: clientInfo.telephone_client,
      emailClient: clientInfo.email_client,
      clientCity: clientInfo.city ? clientInfo.city.id : null,
    });
  };

  useEffect(() => {
    if (AddNewTask.Quantite == "") {
      setAddNewTask({ ...AddNewTask, Quantite: 1 });
    }
  }, [AddNewTask]);

  return (
    <div>
      <Button
        className="nouveauProfilButton"
        style={{ width: "100%" }}
        onClick={handleShow}
      >
        Nouvelle Tâche
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nouvelle Tâche</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={addTaskToTechnicien}>
            <Row className="mb-3">
              {/* //name of client */}
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>Nom complet: </Form.Label>
                <Form.Control
                  list="data"
                  name="nomComplet"
                  autoComplete="off"
                  onChange={(e) =>
                    filterClientInfoByNameClient(e, NewTask.Clients)
                  }
                  value={AddNewTask.nomComplet}
                />
                <datalist id="data">
                  {NewTask.Clients.map((client, index) => (
                    <option key={index}>{client.nomComplet}</option>
                  ))}
                </datalist>
              </Form.Group>
              {/* //type of task */}
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>Type de la tâche: </Form.Label>
                <Form.Control
                  list="dataTask"
                  name="typeTache"
                  autoComplete="off"
                  onChange={handleAddTaskToTechnicien}
                  value={AddNewTask.typeTache}
                />
                <datalist id="dataTask">
                  {NewTask.TypesTasks.map((TypesTask, index) => (
                    <option key={index}>{TypesTask.catache}</option>
                  ))}
                </datalist>
                <span style={{ color: "red" }}>
                  {errorsMessages && errorsMessages.typeTache
                    ? errorsMessages.typeTache[0]
                    : ""}
                </span>
              </Form.Group>
                
            </Row>

            <Row className="mb-3">
              {/* //date work */}
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>Date: </Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="Date"
                  autoComplete="off"
                  onChange={handleAddTaskToTechnicien}
                  value={AddNewTask.Date}
                />
                <span style={{ color: "red" }}>
                  {errorsMessages && errorsMessages.Date
                    ? errorsMessages?.Date[0]
                    : ""}
                </span>
              </Form.Group>
                          {/* //associate to */}

              <Form.Group as={Col} md="6" controlId="">
                <Form.Label>Affectée à : </Form.Label>
                <Form.Control
                  list="userList"
                  name="affecte"
                  autoComplete="off"
                  onChange={handleAddTaskToTechnicien}
                  value={AddNewTask.affecté}
                />
                <datalist id="userList">
                  {filteredUser
                    ? filteredUser.map((user, index) => (
                        <option key={index}>{user.name}</option>
                      ))
                    : ""}
                </datalist>
                <span style={{ color: "red" }}>
                  {errorsMessages && errorsMessages.affecte
                    ? errorsMessages.affecte[0]
                    : ""}
                </span>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  placeholder="description"
                  name="Description"
                  value={AddNewTask.Description}
                  as="textarea"
                  rows={3}
                  onChange={handleAddTaskToTechnicien}
                />
              </Form.Group>
            </Row>

            <Modal.Footer>
              <Button type="submit" variant="primary">
                Ajouter tâche
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
