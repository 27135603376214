import React, { useContext, useEffect, useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Col from "react-bootstrap/Col";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import Form from "react-bootstrap/Form";
import NouvelleTâches from "./Technicien/NouvelleTâches";
import { AuthContext } from "../context/AuthContext";
import STcontext from "../context/STcontext";
import DetailsTaskST from "./DetailsTaskST";
import pusher from "../../pusher";
import { SuccessMessage } from "../errorSuccessNotification/SuccessMessage";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import TuneIcon from "@mui/icons-material/Tune";
import ClearIcon from "@mui/icons-material/Clear";
import AddNewTaskST from "./AddNewTaskST";

const TachesSt = () => {
  const {
    getStTaches,
    handleChangeTaskST,
    makeTaskOnHold,
    tasksST,
    completTaskSubmit,
    delayTaskSubmit,
    cancelTaskSubmit,
    NewTask,
    filterTasksSupport,
    handleSearchTechnicianTaskPerDate,
    handleSearchSTTaskPerDateClicked,
    clearSearchTasksSTPerDate,
    searchnInputTEchnicianPerDate,
  } = useContext(STcontext);
  const { USE } = useContext(AuthContext);

  const [filterTasks, setFilterTasks] = useState([]);

  useEffect(() => {
    getStTaches();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getStTaches();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  useEffect(() => {
    setFilterTasks(tasksST);
    filterTasksST(filterTaks.typeTask);
  }, [tasksST]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    client_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    matricule: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    user_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    assign_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    catache: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filterTaks, setFilterTask] = useState({
    user: null,
    typeTask: null,
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Nom Client, type tâche..."
          />
        </span>
      </div>
    );
  };

  const currenttaskStatus = (taskStatus) => {
    switch (taskStatus) {
      case 0:
        return "en attente";
      case 1:
        return "en cours";
      case 2:
        return "terminé";
      case 3:
        return "reporté";
      case 4:
        return "annulé";
    }
  };

  const handleSelectChange = (e, rowData) => {
    const taskStatus = e.target.value;
    switch (taskStatus) {
      // return the work to state on hold
      case "0":
        makeTaskOnHold(rowData.tache_id);
        break;
      // start work on the task
      case "1":
        handleChangeTaskST(rowData.tache_id);
        break;
      // end work on the task
      case "2":
        completTaskSubmit(rowData.tache_id);
        break;
      case "3":
        // delay the task
        delayTaskSubmit(rowData.tache_id);
        break;
      case "4":
        // cancel the task
        cancelTaskSubmit(rowData.tache_id);
        break;
    }
  };

  const renderTaskStatus = (rowData) => {
    return (
      <Form.Select
        onChange={(e) => handleSelectChange(e, rowData)}
        className="tâches-statut"
        style={{ margin: "5px" }}
      >
        <option selected hidden value={rowData.etat_tache}>
          {currenttaskStatus(rowData.etat_tache)}
        </option>
        <option value={0}>en attente</option>
        <option value={1}>en cours</option>
        <option value={2}>terminé</option>
        <option value={3}>reporté</option>
        <option value={4}>annulé</option>
      </Form.Select>
    );
  };

  const renderMoreDetailsBody = (rowData) => {
    return <DetailsTaskST taskSTToUpdate={rowData} />;
  };

  const handleFilterTaskByType = (e) => {
    setFilterTask({ ...filterTaks, typeTask: e.target.value });
    filterTasksST(e.target.value);
  };

  useEffect(() => {
    if(filterTaks.user != null &&  searchnInputTEchnicianPerDate.startDate != ''){
      handleSearchSTTaskPerDateClicked();
    }
    else if(filterTaks.user != null &&  searchnInputTEchnicianPerDate.startDate == ''){
      filterTasksSupport(filterTaks)
    }
  }, [filterTaks.user]);
  
  const header = renderHeader;
  const taskStatus = renderTaskStatus;
  const moreDetailsBody = renderMoreDetailsBody;

  const filterTasksST = (data) => {
    data == null || data == "all"
      ? setFilterTasks(tasksST)
      : setFilterTasks(tasksST.filter((task) => task.catache == data));
  };

  return (
    <div>
      <SuccessMessage />
      {/* top-bar-section */}
      <div
        className="bar-table"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {/* add new task button section */}
        <div style={{ width: "50%", display: "flex" }}>
          {USE.profil_id == 2 ? <AddNewTaskST /> : ""}
        </div>
        {/* filter tasks by data section */}
        <div
          style={{
            width: "50%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          {/* start date input */}
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Control
                name="startDate"
                type="date"
                value={searchnInputTEchnicianPerDate.startDate}
                onChange={(e) => {
                  handleSearchTechnicianTaskPerDate(e);
                }}
              />
            </Form.Group>
          </Row>
          {/* end date input */}
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Control
                name="endDate"
                type="date"
                value={searchnInputTEchnicianPerDate.endDate}
                onChange={(e) => {
                  handleSearchTechnicianTaskPerDate(e);
                }}
              />
            </Form.Group>
          </Row>
          {/* filter button */}
          <div className="filter-button-task-technicien">
            <Button onClick={() => handleSearchSTTaskPerDateClicked()}>
              <TuneIcon />
              Filtrer
            </Button>
            {/* cance filter button */}
            <Button
              variant="danger"
              onClick={() => clearSearchTasksSTPerDate()}
            >
              {" "}
              <ClearIcon />
              Annule
            </Button>
          </div>
        </div>
      </div>
      <div className="tâches-tech">
        <div className="Table-tech">
          <h3>
            Total Tâches &nbsp; <span class="vl"></span> &nbsp;
            <span style={{ fontSize: "15px" }}>
              {filterTasks ? filterTasks.length : 0} tâches
            </span>
          </h3>

          {/* filter ST task by: */}

          {/* filter by : user name */}

          <Form>
            <Row>
              <Form.Group
                className="mb-4 mx-2"
                as={Col}
                md="2"
                controlId="formBasicEmail"
              >
                <Form.Label>sélectionner les tâches par:</Form.Label>
                <Form.Select
                  name="user"
                  onChange={(e) => {
                    setFilterTask({
                      ...filterTaks,
                      user: e.target.value || null,
                    });
                    handleSearchTechnicianTaskPerDate(e);
                  }}
                >
                  <option selected hidden>
                    Affecter a
                  </option>
                  <option value="all">Tous</option>
                  {NewTask.Users.filter(
                    (technician) => technician.profil_id === 2
                  ).map((technician) => (
                    <option key={technician.id} value={technician.id}>
                      {technician.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-4 mx-2"
                as={Col}
                md="2"
                controlId="formBasicEmail"
              >
                <Form.Label>sélectionner les tâches par:</Form.Label>
                <Form.Select
                  onChange={(e) => {
                    handleFilterTaskByType(e);
                  }}
                >
                  <option selected hidden>
                    Type tâche
                  </option>
                  <option value="all">Tous</option>
                  {NewTask.TypesTasks.map((cataches) => (
                    <option key={cataches.id}>{cataches.catache}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
          </Form>
          <DataTable
            value={filterTasks}
            paginator
            sortMode="multiple"
            removableSort
            rows={10}
            dataKey="tache_id"
            filters={filters}
            globalFilterFields={[
              "client_name",
              "user_name",
              "catache",
              "date_affectation",
              "assign_name",
            ]}
            header={header}
            emptyMessage="Aucune tâche trouvée."
          >
            <Column
              header="Plus Détails"
              style={{ minWidth: "10rem" }}
              body={moreDetailsBody}
            />
            <Column
              field="client_name"
              header="Nom du client"
              style={{ minWidth: "12rem" }}
              sortable
            />
            <Column
              header="Type du tache"
              field="catache"
              style={{ minWidth: "12rem" }}
              sortable
            />
            <Column
              field="date_affectation"
              header="date affectation"
              style={{ minWidth: "12rem" }}
              sortable
            />
            <Column
              header="Envoyer par"
              field="user_name"
              style={{ minWidth: "10rem" }}
              sortable
            />
            <Column
              field="assign_name"
              header="affecter a"
              style={{ minWidth: "6rem" }}
              sortable
            />

            <Column
              field="etat_tache"
              header="Etat tache"
              sortable
              style={{ minWidth: "7rem" }}
              body={taskStatus}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default TachesSt;
