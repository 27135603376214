import _ from "lodash";
import CommercialContext from "../context/CommercialContext";
import { useContext, useEffect, useState } from "react";
import axios from "../context/axios";
import DetailsTaskToConfirmed from "./DetailsTaskToConfirmed";
import pusher from "../../pusher";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import STcontext from "../context/STcontext";
import ConfirmPopup from "../updateAndDeleteButtons/ConfirmPopup";
import NouvelleTâches from "../taskToConfirmeForm/NouvelleTâches";

const SttaskToConfirmed = () => {
  const {
    getTaskInfo,
    taskToConfirmed,
    getTasktoConfirmed,
    deleteTaskToConfirmed,
  } = useContext(STcontext);

  useEffect(() => {
    getTasktoConfirmed();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getTasktoConfirmed();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);
  useEffect(() => {
    getTaskInfo();
  }, []);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    nomComplet: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    catache: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderTaskStatus = (rowData) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <DetailsTaskToConfirmed
          data={rowData}
          getTasktoConfirmed={getTasktoConfirmed}
        />
        <ConfirmPopup
          message={"Etes-vous sûr de vouloir supprimer cette tache?"}
          id={rowData.id}
          deleteFunction={(e) => deleteTaskToConfirmed(e, rowData.id)}
        />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Nom Client, type tâche..."
          />
        </span>
      </div>
    );
  };
  const header = renderHeader;

  const taskStatus = renderTaskStatus;

  return (
    <>
      <div style={{ padding: "20px 20px 30px 20px" }}>
        <div
          className="bar-table"
          style={{ padding: "10px 30px 10px 30px" }}
        >
          <NouvelleTâches/>
        </div>
        <div className="tâches-tech-technicien">
          <div className="Table-tasks">
            <div>
              <h3 style={{ marginBottom: "10px" }}>
                Total Tâches &nbsp; <span class="vl"></span> &nbsp;{" "}
                <span style={{ fontSize: "15px" }}>
                  {" "}
                  {taskToConfirmed ? taskToConfirmed.length : 0} tâches
                </span>{" "}
              </h3>
            </div>

            <DataTable
              value={taskToConfirmed}
              paginator
              sortMode="multiple"
              removableSort
              rows={10}
              dataKey="id"
              filters={filters}
              globalFilterFields={["nomComplet", "catache"]}
              header={header}
              emptyMessage="Aucune tâche trouvée."
            >
              <Column
                field="nomComplet"
                header="Nom du client"
                style={{ minWidth: "12rem" }}
                sortable
              />
              <Column
                header="Telephone"
                field="telephone_client"
                style={{ minWidth: "10rem" }}
                sortable
              />
              <Column
                header="Type du tache"
                field="catache"
                style={{ minWidth: "12rem" }}
                sortable
              />
              <Column
                header="D.previsionnelle debut"
                field="date_previsionnelle_debut"
                style={{ minWidth: "12rem" }}
                sortable
              />
              <Column
                header="Staut"
                style={{ minWidth: "7rem" }}
                body={taskStatus}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default SttaskToConfirmed;
